<template>
  <content-container
    :category="category"
    :breadcrumb="breadcrumb">
    <div
      id="player"
      class="video">
    </div>
  </content-container>
</template>

<script>
  import OvenPlayer from 'ovenplayer'

  export default {
    props: [
      'category',
      'breadcrumb',
      'splitviewMode',
    ],
    data() {
      return {
        player: null,
      }
    },
    mounted() {
      if (this.category) {
        this.player = OvenPlayer.create('player', {
          sources: [
            {
              type: 'hls',
              file: this.category.streamurl
            }
          ]
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .video {
    @apply
      w-full
      h-full
      max-w-full
      max-h-full;
  }
</style>